import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import {
  createResourceLocatorString,
  matchPathname,
  pathByRouteName,
} from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
  manageDisableScrolling,
  isScrollingDisabled,
} from '../../ducks/UI.duck';
import {
  SearchMap,
  ModalInMobile,
  Page,
  SectionSearchbar,
  IconCollection,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.module.css';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

import { types as sdkTypes } from '../../util/sdkLoader';
import { fetchReviewsLite } from '../ListingPage/ListingPage.duck';
import Banner from '../../components/Banner/Banner';

const { LatLng, LatLngBounds } = sdkTypes;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      showList: true,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(
      this.onMapMoveEnd.bind(this),
      SEARCH_WITH_MAP_DEBOUNCE
    );
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const customSearchPagePaths = [
      '/rent-apple-vision-pro/brooklyn-ny',
      '/pressure-washer-rental/brooklyn-ny',
      '/tool-rental/brooklyn-ny',
      '/moped-rental/brooklyn-ny',
      '/bike-rental/brooklyn-ny',
      '/carpet-cleaner-rental/brooklyn-ny',
      '/ladder-rental/brooklyn-ny',
      '/generator-rental/brooklyn-ny',
      '/pallet-jack-rental/brooklyn-ny',
      '/paint-sprayer-rental/brooklyn-ny',
      '/jack-hammer-rental/brooklyn-ny',
      '/air-compressor-rental/brooklyn-ny',
      '/hand-truck-rental/brooklyn-ny',
      '/welder-rental/brooklyn-ny',
      '/nail-gun-rental/brooklyn-ny',
    ];
    const currentPath =
      typeof window !== 'undefined' &&
      window.location &&
      window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage =
      currentPath === searchPagePath ||
      customSearchPagePaths.includes(currentPath);

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance
        ? { origin: viewportCenter }
        : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      const matchRoutes = matchPathname(
        location.pathname,
        routeConfiguration()
      );
      const routeName = matchRoutes[0]?.route?.name || 'SearchPage';
      history.push(
        createResourceLocatorString(routeName, routes, {}, searchParams)
      );
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  componentDidMount() {
    const { location, filterConfig, currentUser, listings } = this.props;

    const { address, streetAddress, city, state } =
      currentUser?.attributes?.profile?.publicData || {};
    const upadteAddress =
      (address && address !== ' ') || streetAddress
        ? streetAddress + ' ' + city + ' ' + state
        : city + ' ' + state;

    // const zipLocation = protectedData?.zipLocation;
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const validQueryParams = validURLParamsForExtendedData(
      searchInURL,
      filterConfig
    );
    const fullAddress = validQueryParams?.address?.split(',');

    const userAddress = fullAddress ? fullAddress : upadteAddress?.split(' ');
    if (
      userAddress.length >= 4 &&
      userAddress[0] !== 'undefined' &&
      userAddress[0] !== '' &&
      userAddress[1] !== 'undefined' &&
      userAddress[1] !== ''
    ) {
      this.setState({ showList: false });
    } else {
      this.setState({ showList: true });
    }
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      currentUser,
      currentUserListing,
      bookmarkedData,
      activeListingId,
      onActivateListing,
      reviews,
    } = this.props;

    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(
      searchInURL,
      filterConfig,
      sortConfig
    );

    const isAppleVisionPro = location?.pathname === '/rent-apple-vision-pro/brooklyn-ny';
    const isPressureWasherPro =
      location?.pathname === '/pressure-washer-rental/brooklyn-ny';
    const isToolPro =
      location?.pathname === '/tool-rental/brooklyn-ny';
    const isMopedPro =
      location?.pathname === '/moped-rental/brooklyn-ny';
    const isBikePro =
      location?.pathname === '/bike-rental/brooklyn-ny';
    const isCarpetCleaner =
      location?.pathname === '/carpet-cleaner-rental/brooklyn-ny';
    const isLadder = location?.pathname === '/ladder-rental/brooklyn-ny';
    const isGenerator = location?.pathname === '/generator-rental/brooklyn-ny';
    const isPalletJack =
      location?.pathname === '/pallet-jack-rental/brooklyn-ny';
    const isPaintSprayer =
      location?.pathname === '/paint-sprayer-rental/brooklyn-ny';
    const isJackHammer =
      location?.pathname === '/jack-hammer-rental/brooklyn-ny';
    const isAirCompressor =
      location?.pathname === '/air-compressor-rental/brooklyn-ny';
    const isHandTruck = location?.pathname === '/hand-truck-rental/brooklyn-ny';
    const isWelder = location?.pathname === '/welder-rental/brooklyn-ny';
    const isNailGun = location?.pathname === '/nail-gun-rental/brooklyn-ny';

    const pageInfo = {
      '/pressure-washer-rental/brooklyn-ny': {
        title: 'Pressure Washer Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Power washers starting at $15. Runtize delivers pressure washers and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/tool-rental/brooklyn-ny': {
        title: 'Tools Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Tools starting at $15. Runtize delivers tools and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/moped-rental/brooklyn-ny': {
        title: 'Mopes Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Mopes starting at $15. Runtize delivers mopes and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/bike-rental/brooklyn-ny': {
        title: 'Bikes Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Bikes starting at $15. Runtize delivers bikes and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/carpet-cleaner-rental/brooklyn-ny': {
        title: 'Carpet Cleaner Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Carpet Cleaners starting at $15. Runtize delivers carpet cleaners and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/ladder-rental/brooklyn-ny': {
        title: 'Ladder Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Ladders starting at $15. Runtize delivers ladders and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/generator-rental/brooklyn-ny': {
        title: 'Generator Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Generators starting at $15. Runtize delivers generators and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/pallet-jack-rental/brooklyn-ny': {
        title: 'Pallet Jack Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Pallet Jack starting at $15. Runtize delivers pallet jack and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/paint-sprayer-rental/brooklyn-ny': {
        title: 'Paint Sprayer Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Paint Sprayers starting at $15. Runtize delivers paint sprayers and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/jack-hammer-rental/brooklyn-ny': {
        title: 'Jack Hammer Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Jack Hammers starting at $15. Runtize delivers jack hammers and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/air-compressor-rental/brooklyn-ny': {
        title: 'Air Compressor Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Air Compressors starting at $15. Runtize delivers air compressors and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/hand-truck-rental/brooklyn-ny': {
        title: 'Hand Truck Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Hand Trucks starting at $15. Runtize delivers hand trucks and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/welder-rental/brooklyn-ny': {
        title: 'Welder Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Welders starting at $15. Runtize delivers welders and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
      '/nail-gun-rental/brooklyn-ny': {
        title: 'Nail Gun Rental Brooklyn, NY | Door Delivered On Demand',
        description:
          'Nail Guns starting at $15. Runtize delivers nail guns and other rentals straight to your door. We deliver and pick it back up on demand.',
      },
    };

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync =
      urlQueryString === paramsQueryString ||
      isAppleVisionPro ||
      isPressureWasherPro ||
      isToolPro ||
      isMopedPro ||
      isBikePro ||
      isCarpetCleaner ||
      isLadder ||
      isGenerator ||
      isPalletJack ||
      isPaintSprayer ||
      isJackHammer ||
      isAirCompressor ||
      isHandTruck ||
      isWelder ||
      isNailGun;

    const onReload = () => {
      setTimeout(
        () => typeof window !== 'undefined' && window.location.reload(false),
        1000
      );
      clearTimeout();
    };
    const validQueryParams = validURLParamsForExtendedData(
      searchInURL,
      filterConfig
    );
    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout =
      isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };
    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      address,
      intl
    );

    const { zipCodeLatLng } =
      currentUser?.attributes?.profile?.protectedData || {};
    var currentUserBounds =
      zipCodeLatLng &&
      LatLngBounds &&
      new LatLngBounds(
        new LatLng(zipCodeLatLng[3], zipCodeLatLng[2]),
        new LatLng(zipCodeLatLng[1], zipCodeLatLng[0])
      );
    var currentOrigin = origin
      ? origin
      : currentUser?.attributes?.profile?.protectedData?.origin;
    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;
    const defaultBounds = new LatLngBounds(
      new LatLng(41.40102755630302, -72.9909935130689),
      new LatLng(39.95532924369698, -74.89732228693109)
    );
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>

    let pageTitle = title;
    let pageDescription = description;
    const currentPath = location.pathname;
    pageTitle = pageInfo[currentPath]?.title || title;
    pageDescription = pageInfo[currentPath]?.description || description;

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={pageDescription}
        title={pageTitle}
        schema={schema}
        className={css.searchBarMain}
      >
        {(isAppleVisionPro ||
          isPressureWasherPro ||
          isMopedPro ||
          isToolPro ||
          isBikePro ||
          isCarpetCleaner ||
          isLadder ||
          isGenerator ||
          isPalletJack ||
          isPaintSprayer ||
          isJackHammer ||
          isAirCompressor ||
          isHandTruck ||
          isWelder ||
          isNailGun) && <Banner />}
        <TopbarContainer
          active="howItWorks"
          showListState={shouldShowSearchMap}
          showList={this.state.showList}
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
          rootClassName={css.topbar}
        />

        <div
          className={classNames(
            css.mainContainer,
            this.state.showList && css.mapMainContainer
          )}
        >
          <div className={css.container}>
            <MainPanel
              showList={this.state.showList}
              currentUser={currentUser}
              urlQueryParams={validQueryParams}
              listings={listings}
              onReload={onReload}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              searchParamsAreInSync={searchParamsAreInSync}
              onActivateListing={onActivateListing}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              onMapIconClick={onMapIconClick}
              isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
              pagination={pagination}
              searchParamsForPagination={parse(location.search)}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              history={history}
              location={location}
              showMap={() => {
                this.setState({
                  showList: true,
                  isSearchMapOpenOnMobile: true,
                });
              }}
              showLists={() => this.setState({ showList: false })}
              showListState={this.state.showList}
            />
            {this.state.showList && (
              <ModalInMobile
                mapMobile={true}
                className={css.mapPanel}
                id="SearchPage.map"
                isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                onClose={() =>
                  this.setState({ isSearchMapOpenOnMobile: false })
                }
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.mapWrapper}>
                  {shouldShowSearchMap ? (
                    <>
                      <SearchMap
                        reusableContainerClassName={css.map}
                        activeListingId={activeListingId}
                        bounds={
                          bounds
                            ? bounds
                            : currentUserBounds
                            ? currentUserBounds
                            : defaultBounds
                        }
                        newBounds={bounds ? bounds : currentUserBounds}
                        center={currentOrigin}
                        bookmarkedData={bookmarkedData}
                        currentUser={currentUser}
                        currentUserListing={currentUserListing}
                        isSearchMapOpenOnMobile={
                          this.state.isSearchMapOpenOnMobile
                        }
                        location={location}
                        listings={mapListings || []}
                        reviews={reviews}
                        onMapMoveEnd={this.onMapMoveEnd}
                        onCloseAsModal={() => {
                          onManageDisableScrolling('SearchPage.map', false);
                        }}
                        messages={intl.messages}
                        zoom={13}
                      />
                      <div
                        onClick={() =>
                          this.setState({ isSearchMapOpenOnMobile: false })
                        }
                        className={css.listButton}
                      >
                        <IconCollection name="LIST_MAP_ICON" />
                        <p>
                          {' '}
                          <FormattedMessage id="SearchPage.list" />
                        </p>
                      </div>
                    </>
                  ) : null}
                </div>
              </ModalInMobile>
            )}
          </div>
        </div>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    reviews,
  } = state.SearchPage;
  const { currentUser, currentUserListing } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(
      currentPageResultIds,
      searchMapListingIds,
      (id1, id2) => id1.uuid === id2.uuid
    )
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    currentUser,
    currentUserListing,
    activeListingId,
    reviews,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams =>
    dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  bookmarkedData: id => dispatch(updateProfile(id)),
  fetchReviewOnce: id => dispatch(fetchReviewsLite(id)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
